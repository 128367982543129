#form-collect {
    min-width: 33%;
    margin: 0px 5px 10px 5px;
}

#theme-box-border {
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.61);
    -webkit-box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.61);
}

/* input,
textarea {
    background-color: #f2f2f285 !important;
} */

/* media quires */
/* @media only screen and (max-width: 1130px) {
    #form-collect {
        width: 100%;
    }
} */

/* Upload button  */
.file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file>input[type='file'] {
    display: none
}

.file>label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    background-color: hsl(0, 0%, 100%);
    color: hsl(0, 0%, 29%);
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.file>label:hover {
    border-color: hsl(0, 0%, 21%);
}

.file>label:active {
    background-color: hsl(0, 0%, 96%);
}

.file>label>i {
    padding-right: 5px;
}

.file--upload>label {
    color: hsl(204, 86%, 53%);
    border-color: hsl(204, 86%, 53%);
}

.file--upload>label:hover {
    border-color: hsl(204, 86%, 53%);
    background-color: hsl(204, 86%, 96%);
}

.file--upload>label:active {
    background-color: hsl(204, 86%, 91%);
}

.blah {
    height: 59px;
    border-radius: 7px;
    margin: 0px 10px;
}
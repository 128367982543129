/* #theme1 {
  width: 90%;
  margin: auto;
} */


/* Skills Set  */
.skill-badge {
  background-color: #D2E4E1 !important;
  color: black !important;
  margin: 5px;
}

.skillBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.basic-set {
  /* width: 61%; */
  margin: 0rem 5rem;
  padding-top: 10px;
}


/* Utility  */
.subBox {
  margin-bottom: 15px;
}

.sub-details {
  padding: 0px 10px;
}

.sub-title {
  font-size: 1.1rem;
  font-weight: 600;
}

/* media quires */
@media only screen and (max-width: 450px) {
  .basic-set {
    margin: 0rem 1rem;
  }
}
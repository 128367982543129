.Toggle {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .app-container {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .toggle-switch {
    width: 100px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .toggle-switch.on {
    background-color: #00d084;
  }
  
  .toggle-switch.off {
    background-color: #ccc;
  }
  
  .toggle-knob {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  
  .toggle-switch.on .toggle-knob {
    left: 50px;
  }
  
  .toggle-icon {
    font-size: 24px;
  }
  
  .toggle-switch.on .toggle-icon {
    color: white;
  }
  
  .toggle-switch.off .toggle-icon {
    color: gray;
  }
  